import * as React from "react";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Portal from "../components/portal";

import "../styles/global.scss";

// markup
const IndexPage = () => {
  return (
    <Layout title="The Edward Hotel">
      <Hero
        title="404 Page Not Found"
        text="Oops...something went wrong."
        page="404"
        center
      />
      <Portal />
    </Layout>
  );
};

export default IndexPage;
